import { ApolloError, useQuery } from "@apollo/client";

import { gql } from "@apollo/client";
import { seoMetaTags } from "./model";
import { useRouter } from "next/router";

interface SeoHookResponse {
	seoMetaTags: seoMetaTags;
	loading: boolean;
	error: ApolloError;
}

const QUERY_SEO = gql`
	query SEO($url: String!) {
		seoMetaTags(url: $url) {
			h1
			title
			breadcrumbs {
				text
				url
			}
			url
			description
			og_image
			noindex
			canonical
			site_name
			links
			text
			seoLanding {
				text
				image
				id
			}
			ios_app_id
		}
	}
`;

export const useSEO = (): SeoHookResponse => {
	const router = useRouter();

	const { data, loading, error } = useQuery(QUERY_SEO, {
		variables: {
			url: router.asPath,
		},
	});

	let seoMetaTags: any = { ...data?.seoMetaTags };
	if (seoMetaTags && seoMetaTags.breadcrumbs?.length > 1) {
		seoMetaTags["lastSearch"] = seoMetaTags.breadcrumbs[seoMetaTags.breadcrumbs.length - 2];
	}

	return {
		loading,
		seoMetaTags: seoMetaTags,
		error,
	};
};
