import React, { useContext } from "react";
import Head from "next/head";

import { useSEO } from "../hook";
import getConfig from "next/config";
import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { useTheme } from "../../../Styles/ThemeHook";
import { useWaitUsrInteraction } from "shared-components/GlobalHooks/web/useWaitUsrInteraction";
import { useFilters } from "shared-components/Components/Filters/Filters.hook";
import { useRouter } from "next/router";
const { NODE_ENV } = getConfig().publicRuntimeConfig;

export const MetaTags = () => {
	const { seoMetaTags, error } = useSEO();
	const { pinterest_id, site_name } = useContext(ConfigStateContext);
	const { hasInteract } = useWaitUsrInteraction();
	const { theme } = useTheme();
	const { filters } = useFilters();
	const { pathname } = useRouter();
	const { country_code } = useContext(ConfigStateContext)

	if (error || !seoMetaTags) return null;

	const og_image = seoMetaTags.og_image;

	const description = seoMetaTags?.description
		?.split("<br>")
		.join("")
		.replace(/\r\n/g, "");

	const keywords = "Inmuebles, casas, apartamentos, alquiler, venta";

	return (
		<>
			<Head>
				<meta name="author" content="InfoCasas" />
				<link rel="icon" href={theme.images.favicon} type="image/x-icon" />
				<meta name="referrer" content="no-referrer-when-downgrade" />
				<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
				<meta name="author" content="InfoCasas" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
				/>
				<title key="title">{seoMetaTags.title || site_name}</title>
				<link rel="canonical" href={seoMetaTags.canonical} />
				<meta key="description" name="description" content={description} />
				<meta key="og:url" name="og:url" property="og:url" content={seoMetaTags.url} />
				<meta key="og:type" name="og:type" property="og:type" content="website" />
				<meta
					key="og:title"
					name="og:title"
					property="og:title"
					content={seoMetaTags.title}
				/>
				<meta
					key="og:description"
					name="og:description"
					property="og:description"
					content={description}
				/>
				<meta key="og:image" name="og:image" property="og:image" content={og_image} />
				<meta
					key="og:site_name"
					name="og:site_name"
					property="og:site_name"
					content={seoMetaTags.site_name}
				/>
				<meta name="keywords" content={keywords} />
				<meta name="p:domain_verify" content={pinterest_id} />

				{seoMetaTags?.ios_app_id && <meta name="apple-itunes-app" content={`app-id=${seoMetaTags?.ios_app_id}, app-argument=${seoMetaTags?.canonical}`} />}
				{NODE_ENV !== "production" && <meta name="robots" content="noindex, nofollow" />}
				{country_code == 'BO' && filters?.page > 1 && pathname.includes("searchPage") &&
					<meta name="robots" content="noindex,follow" />
				}
			</Head>
		</>
	);
};
